
import Vue from "vue";

export default Vue.extend({
  name: "CommentForm",
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  },
  props: {
    isMentorcast: {
      type: Boolean
    },
    title: String
  },
  data() {
    return {
      data: {
        message: "",
        search: ""
      },
      maxCommentChars: 250,
      // New post content buttons
      buttons: [
        {
          name: "Photo",
          icon: "picture",
          action: () => console.log("Photo was clicked!")
        },
        {
          name: "Video",
          icon: "video",
          action: () => console.log("Video was clicked!")
        },
        {
          name: "Event",
          icon: "event",
          action: () => console.log("Event was clicked!")
        },
        {
          name: "Article",
          icon: "article",
          action: () => console.log("Article was clicked!")
        }
      ],
      postBtnLoading: false
    };
  },
  methods: {
    onPost() {
      const id = this.isMentorcast ? "mentorcast_id" : "post_id";
      const type = this.isMentorcast ? "mentorcast" : "post";
      this.postBtnLoading = true;

      this.$store
        .dispatch("comment/CREATE_COMMENT", {
          type,
          data: { [id]: this.$route.params.id, ...this.data }
        })
        .then(data => {
          this.postBtnLoading = false;
          this.$emit("update", data);
          this.data.message = "";
        })
        .catch(error => {
          this.postBtnLoading = false;
          if (error.response.status === 422) {
            for (const e of error.response.data.errors) {
              // @ts-ignore
              this.$alertify.error(e);
            }
          }
        });
    },
    insert(emoji: any) {
      this.data.message += emoji;
    }
  }
});
